import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL, POST_USER_REGISTER } from "../../constant/constant";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const Login = () => {
  const [bsgUid, setBsgUid] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [gender, setGender] = useState("");
  const [parchmentdate, setParchmentDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [name, setName] = useState("");
  const [certificateDate, setCertificateDate] = useState("");
  const [certificateNumber, setCertificateNumber] = useState("");
  const [place, setPlace] = useState("");
  const [leader, setLeader] = useState("");
  const [userData, setUserData] = useState([]);
  const [issue, setIssue] = useState("");
  const [section, setSection] = useState("");
  const [state, setState] = useState([]);
  const token = localStorage.getItem("adminToken");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const excelDateToJSDate = (serial) => {
    if (typeof serial === "number") {
      const date = new Date((serial - 25569) * 86400 * 1000);
      const day = ("0" + date.getUTCDate()).slice(-2);
      const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
      const year = date.getUTCFullYear();
      return `${day}-${month}-${year}`;
    }
    return serial; // Return as-is if it's not a number
  };

  const handleFileUpload = (data) => {
    console.log(data, "data");
    data.forEach((item) => {
      const date = item?.dob ? excelDateToJSDate(item.dob) : null;
      const DATE = item?.DOB ? excelDateToJSDate(item.DOB) : null;
      const PARCHMENT_DATE = item?.PARCHMENT_DATE
        ? excelDateToJSDate(item.PARCHMENT_DATE)
        : null;
      const FROM_DATE = item?.FROM_DATE
        ? excelDateToJSDate(item.FROM_DATE)
        : null;
      const TO_DATE = item?.TO_DATE ? excelDateToJSDate(item.TO_DATE) : null;
      const toDate = item?.toDate ? excelDateToJSDate(item.toDate) : null;

      const CERTIFICATE_DATE = item?.CERTIFICATE_DATE
        ? excelDateToJSDate(item.CERTIFICATE_DATE)
        : null;
      const fromDate = item?.fromDate ? excelDateToJSDate(item.fromDate) : null;
      const parchmentdate = item?.parchmentdate
        ? excelDateToJSDate(item.parchmentdate)
        : null;

      console.log(
        DATE,
        date,
        toDate,
        FROM_DATE,
        TO_DATE,
        PARCHMENT_DATE,
        fromDate,
        parchmentdate,
        CERTIFICATE_DATE
      );

      // Create the formatted object
      const formattedItem = {
        ...item,
        dob: date || item.dob,
        DOB: DATE || item.DOB,
        // Use the formatted date or the original if it's null
        toDate: toDate || item.toDate,
        fromDate: fromDate || item.fromDate,
        parchmentdate: parchmentdate || item.parchmentdate,
        FROM_DATE: FROM_DATE || item.FROM_DATE,
        TO_DATE: TO_DATE || item.TO_DATE,
        PARCHMENT_DATE: PARCHMENT_DATE || item.parchmentdate,
      };

      console.log(formattedItem, "formattedItem");

      setUserData((prevData) => [...prevData, formattedItem]); // Add each item to the state one by one
    });
  };

  const handleEdit = (index) => {
    const updatedData = [...userData];
    updatedData[index].editMode = true;
    setUserData(updatedData);
  };

  const handleSave = (index) => {
    const updatedData = [...userData];
    updatedData[index].editMode = false;
    setUserData(updatedData);
  };

  const handleSubmitxl = async (e) => {
    e.preventDefault();

    try {
      // Iterate through the userData array and send each object one by one
      for (const user of userData) {
        const dataToSend = { ...user }; // Destructure the individual user data to send as an object
        console.log(dataToSend, "data to send");

        await axios.post(POST_USER_REGISTER,axiosConfig, dataToSend);
      }
      toast.success("Excel file submitted successfully");

      setUserData([]); // Clear user data after all have been sent
    } catch (error) {
      console.log(error, "error");
      console.log(error.response, "response");
      console.log(error.response.status, "responsestatus");
      if (error.response) {
        // Check for specific error messages
        if (
          error.response.data &&
          (error.response.data.error === "User already Exist" ||
            error.response.status === 409)
        ) {
          // Specific error when user already exists
          toast.error("User already exists. Please check the entered data.");
        } else if (error.response.status === 409) {
          // General 409 error without specific message
          toast.error("An error occurred while submitting Excel data.");
        } else {
          // Handle other types of errors
          toast.error("An unexpected error occurred. Please try again.");
        }
      } else {
        // Handle network errors or if error.response does not exist
        toast.error("Network error. Please check your connection.");
      }
    }
  };

  useEffect(() => {
    getState();
  }, []);

  const getState = async () => {
    try {
      const userId = localStorage.getItem("id");
      console.log(userId, "userId");
      const response = await axios.get(
        `${BASE_URL}/api/v1/getstate/${userId}`
      );
      console.log(response.data, "response.data");
      setState(response.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedDob = dob.split("-").reverse().join("-");

    const data = {
      bsgUid,
      dob: formattedDob,
      email,
      FROM_DATE: fromDate,
      TO_DATE: toDate,
      name,
      CERTIFICATE_NUMBER: certificateNumber,
      CERTIFICATE_DATE: certificateDate,
      LEADER: leader,
      PLACE: place,
      GENDER: gender,
      ISSUED_DATE: issue || parchmentdate,
      SECTION: section,
      STATE: selectedState,
    };
    console.log(data, "data");
    try {
      const response = await axios.post(POST_USER_REGISTER, data, axiosConfig);
      console.log(response, "response");
      const token = response.data.token;
      localStorage.setItem("authToken", token);
      Cookies.set("token", token, { expires: 7 });
      toast.success("Add Pre-ALT Leader successfully");
      setBsgUid("");
      setDob("");
      setEmail("");
      setName("");
      setLeader("");
      setCertificateDate("");
      setCertificateNumber("");
      setFromDate("");
      setToDate("");
      setPlace("");
    } catch (error) {
      console.log(error, "error");
      console.log(error.response, "response");
      console.log(error.response.status, "responsestatus");
      if (error.response) {
        // Check for specific error messages
        if (
          error.response.data &&
          (error.response.data.error === "User already Exist" ||
            error.response.status === 409)
        ) {
          // Specific error when user already exists
          toast.error("User already exists. Please check the entered data.");
        } else if (error.response.status === 409) {
          // General 409 error without specific message
          toast.error("An error occurred while submitting Excel data.");
        } else {
          // Handle other types of errors
          toast.error("An unexpected error occurred. Please try again.");
        }
      } else {
        // Handle network errors or if error.response does not exist
        toast.error("Network error. Please check your connection.");
      }
    }
  };

  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();
    const data = [
      [
        "SL",
        "name",
        "STATE",
        "SECTION",
        "GENDER",
        "email",
        "MOBILE",
        "bsgUid",
        "dob",
        "ADDRESS",
        "CERTIFICATE_NUMBER",
        "CERTIFICATE_DATE",
        "TO_DATE",
        "FROM_DATE",
        "LEADER",
        "PLACE",
        "ISSUED_DATE",
      ],
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const workbookBinary = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "binary",
    });
    const blob = new Blob([s2ab(workbookBinary)], {
      type: "application/octet-stream",
    });
    saveAs(blob, "hwb.xlsx");
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  return (
    <>

<div className="bg-blue-100 border-l-4 border-blue-500 p-2 rounded-md shadow-lg ">
    <div className="flex items-start">
      <svg
        className="h-6 w-6 text-blue-500 mt-0.5 mr-2"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 16h-1v-4h-1m0-4h.01M12 4v4m-6 8h12v2H6v-2z"
        />
      </svg>
      <div className="text-blue-800 ">
        <span className="font-semibold text-red-600">Disclaimer:</span> 
        This form is for HWB candidates who have completed the course; upon submission, the system will automatically generate the parchment number. 
      </div>
    </div>
  </div>
      <h1 className="flex justify-center font-bold text-3xl py-8">
        HWB HOLDER
      </h1>
      <div className="flex flex-row">
        <div>
          <label className="text-md font-bold text-black flex justify-start">
            Download Excel File
          </label>
          <button
            className="w-auto bg-gray-700 font-medium text-white px-3 py-1.5 mt-2 rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
            onClick={handleDownload}
          >
            Download
          </button>
        </div>
        <div className="ml-8">
          <label className="text-md font-bold text-black">
            Upload Excel File
          </label>
          <input
            type="file"
            onChange={(e) => {
              const file = e.target.files[0];
              if (!file) return;
              const reader = new FileReader();
              reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet);
                handleFileUpload(jsonData);
              };
              reader.readAsArrayBuffer(file);
            }}
            accept=".xlsx, .xls"
            className="bg-slate-200 p-1 outline-none mt-1 block w-56 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
      </div>

      {userData.length > 0 && (
        <div className=" mt-4  ">
          <h2 className="font-bold text-2xl mt-10 uppercase">
            User Data Preview
          </h2>
          <div>
            <div
              className="overflow-auto"
              style={{ maxHeight: "400px", width: "940px" }}
            >
              <table className="mt-2 text-xs">
                <thead>
                  <tr className="bg-gray-200 text-xs">
                    <th className="border">SL</th>
                    <th className="border">NAME</th>
                    <th className="border">STATE</th>
                    <th className="border">SECTION</th>
                    <th className="border">GENDER</th>
                    <th className="border">Email</th>
                    <th className="border">MOBILE</th>
                    <th className="border">BSGUID</th>
                    <th className="border">DOB</th>
                    <th className="border">ADDRESS</th>
                    <th className="border uppercase">Certificate Number</th>
                    <th className="border uppercase">Certificate Date</th>
                    <th className="border uppercase">
                      Course StartDate (DD/MM/YYYY)
                    </th>
                    <th className="border uppercase">
                      Course EndDate (DD/MM/YYYY)
                    </th>
                    <th className="border uppercase">Leader of the Course</th>
                    <th className="border uppercase">Place</th>
                    <th className="border uppercase"> Parchment Issue</th>

                    <th className="border uppercase">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.map((user, index) => (
                    <tr key={index} className="text-xs">
                      <td className="border">
                        {user.editMode ? (
                          <input
                            type="text"
                            value={user.SL}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].SL = e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.SL
                        )}
                      </td>

                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="text"
                            value={user.name}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].name = e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.name
                        )}
                      </td>
                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="text"
                            value={user.STATE}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].STATE = e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.STATE
                        )}
                      </td>

                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="text"
                            value={user.SECTION}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].SECTION = e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.SECTION
                        )}
                      </td>

                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="text"
                            value={user.GENDER}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].GENDER = e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.GENDER
                        )}
                      </td>

                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="text"
                            value={user.email}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].email = e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.email
                        )}
                      </td>

                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="text"
                            value={user.MOBILE}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].MOBILE = e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.MOBILE
                        )}
                      </td>

                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="text"
                            value={user.bsgUid}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].bsgUid = e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.bsgUid
                        )}
                      </td>

                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="date"
                            value={user.dob}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].dob = e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.dob
                        )}
                      </td>

                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="text"
                            value={user.ADDRESS}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].ADDRESS = e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.ADDRESS
                        )}
                      </td>

                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="text"
                            value={user.CERTIFICATE_NUMBER}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].CERTIFICATE_NUMBER =
                                e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.CERTIFICATE_NUMBER
                        )}
                      </td>

                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="date"
                            value={user.CERTIFICATE_DATE}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].CERTIFICATE_DATE =
                                e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.CERTIFICATE_DATE
                        )}
                      </td>

                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="date"
                            value={user.TO_DATE}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].TO_DATE = e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.TO_DATE
                        )}
                      </td>

                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="date"
                            value={user.FROM_DATE}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].FROM_DATE = e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.FROM_DATE
                        )}
                      </td>

                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="text"
                            value={user.LEADER}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].LEADER = e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.LEADER
                        )}
                      </td>

                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="text"
                            value={user.PLACE}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].PLACE = e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.PLACE
                        )}
                      </td>

                      <td className="border ">
                        {user.editMode ? (
                          <input
                            type="text"
                            value={user.ISSUED_DATE}
                            onChange={(e) => {
                              const updatedData = [...userData];
                              updatedData[index].ISSUED_DATE = e.target.value;
                              setUserData(updatedData);
                            }}
                          />
                        ) : (
                          user.ISSUED_DATE
                        )}
                      </td>

                      {/* Other table cells remain unchanged */}

                      <td className="border ">
                        {user.editMode ? (
                          <button
                            onClick={() => handleSave(index)}
                            className="text-blue-600"
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            onClick={() => handleEdit(index)}
                            className="text-blue-600"
                          >
                            Edit
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <button
            className="bg-gray-700 text-white font-medium px-4 py-2 mt-4 rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
            onClick={handleSubmitxl}
          >
            Submit Data
          </button>
        </div>
      )}

      <h2 className="text-3xl font-extrabold text-center text-gray-800 my-5">
        ADD HWB FORM
      </h2>
      <form
        className="max-w-4xl mx-auto p-8 bg-white rounded-lg shadow-lg mt-6 border border-gray-300 transition-all duration-300 hover:shadow-xl"
        onSubmit={handleSubmit}
      >
        <ToastContainer />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="bsgUid"
            >
              BSG UID Number
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="bsgUid"
              placeholder="Enter the UID Number"
              type="text"
              value={bsgUid}
              onChange={(e) => setBsgUid(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="dob"
            >
              Date of Birth
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="dob"
              type="date"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="email"
              placeholder="Enter the Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="name"
            >
              Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="name"
              placeholder="Enter the Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="gender"
            >
              Gender
            </label>
            <select
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="section"
            >
              Section
            </label>
            <select
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="section"
              value={section}
              onChange={(e) => setSection(e.target.value)}
            >
              <option value="">Select Section</option>
              <option value="Cub">Cub</option>
              <option value="Ranger">Ranger</option>
              <option value="Rover">Rover</option>
              <option value="BulBul">Bulbul</option>
              <option value="Scout">Scout</option>
              <option value="Guide">Guide</option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="fromDate"
            >
              From Date
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="fromDate"
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="toDate"
            >
              To Date
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="toDate"
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="certificateNumber"
            >
              Certificate Number
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="certificateNumber"
              placeholder="Enter the Certificate Number"
              type="text"
              value={certificateNumber}
              onChange={(e) => setCertificateNumber(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="certificateDate"
            >
              Certificate Date
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="certificateDate"
              type="date"
              value={certificateDate}
              onChange={(e) => setCertificateDate(e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="place"
            >
              Place
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="place"
              placeholder="Enter the Place Name"
              type="text"
              value={place}
              onChange={(e) => setPlace(e.target.value)}
            />
          </div>
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="leader"
            >
              Leader
            </label>
            <input
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="leader"
              placeholder="Enter the Leader Name"
              type="text"
              value={leader}
              onChange={(e) => setLeader(e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="issue"
            >
              Issue Parchment
            </label>
            <select
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="issue"
              value={issue}
              onChange={(e) => setIssue(e.target.value)}
            >
              <option value="">Choose Parchment</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
              htmlFor="state"
            >
              State
            </label>
            <select
              className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
              id="state"
              value={selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
            >
              <option value="">Select State</option>
              {state.map((item) => (
                <option key={item._id} value={item.state}>
                  {item.state}
                </option>
              ))}
            </select>
          </div>
          {issue === "yes" && (
            <div className="w-full">
              <label
                className="block uppercase tracking-wide text-gray-700 text-sm font-semibold mb-2"
                htmlFor="parchmentDate"
              >
                Parchment Date
              </label>
              <input
                className="appearance-none block w-full bg-gray-100 text-gray-800 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500"
                id="parchmentDate"
                type="date"
                value={parchmentdate}
                onChange={(e) => setParchmentDate(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className="flex justify-center mt-6">
          <button
            className="bg-indigo-600 text-white font-semibold px-6 py-3 rounded-lg hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700 transition duration-300 ease-in-out"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>

    </>
  );
};

export default Login;
