import React, { useState } from "react";
import LogoImg from "../img/logo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../constant/constant";

const Login = () => {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [admin, setAdmin] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("adminToken");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
   

    const data = {
      username: username,
      password: password,
      role:role
    };
    console.log(data, "data");
    try {
      let response;
      if (admin) {
        response = await axios.post(
          `${BASE_URL}/auth/Register`,axiosConfig,
          data
        );
        console.log(response.data, "response");

   
        toast.success("SuperAdmin registered successfully");
      } else {
        response = await axios.post(`${BASE_URL}/auth/login`, data);
        console.log(response.data, "data");
        // Cookies.set("token", token, { expires: 7 });
        
        // const token = response.data.token;
        // console.log(token, "token");
        // const token1 = Cookies.set("token", token, { expires: 7 });
        // console.log(token1, "tokenq");
       
        toast.success("Login Successfully")
      }
    } catch (error) {
      console.error("Error:", error);
      console.log(error.response.data.message, "error");
      if (
        error.response.data.message ===
        "User already exists. Please login with BsgUid and password."
      ) {
        navigate("/newuserlogin");
        toast.error(
          "User already exists. Please login with BsgUid and password."
        );
      }
    }
  };

  const toggleUserMode = () => {
    setAdmin(!admin);

    setUsername("");
    setPassword("");
  };

  return (
    <div className="flex items-center justify-center my-20 ">
      <ToastContainer />

  
      <div className="bg-white p-8 rounded shadow-lg max-w-md w-full">
        <img src={LogoImg} alt="Logo" className="" />
        <h2 className="text-2xl font-bold text-center mb-6">
          {admin ? "Register to ADMIN" : "Login to ADMIN"}
        </h2>

        <form onSubmit={handleSubmit}>
          {!admin && (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  UserName
                </label>
                <input
                  type="text"
                  id="bsgUid"
                  className="bg-slate-200 py-2 px-2 outline-none mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder="Enter BSG UID Number"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
               
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  type="text"
                  className="bg-slate-200 px-2 py-2 outline-none mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
             
            </>
          )}

          {admin && (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  UserName
                </label>
                <input
                  type="text"
                  id="bsgUid"
                  className="bg-slate-200 py-2 px-2 outline-none mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder="Enter BSG UID Number"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
              
              
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  type="text"
               
                  
                  className="bg-slate-200 px-2 py-2 outline-none mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
               
                  className="block text-sm font-medium text-gray-700"
                >
                  Role
                </label>
                <input
                  type="text"
                  className="bg-slate-200 px-2 py-2 outline-none mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                />
              </div>
            </>
          )}

          <button
            type="submit"
            className="w-full bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
          >
            {admin ? "Register" : "Login"}
          </button>
        </form>
        <div className="mt-4 text-center">
          <button
            className="text-indigo-500 hover:text-indigo-700 text-sm focus:outline-none"
            onClick={toggleUserMode}
          >
            {admin ? "Existing User? Login Here" : "New User? Register Here"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
