import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../constant/constant";
import { ThreeDots } from "react-loader-spinner";
import Modal from "react-modal";
import Swal from "sweetalert2";
const HwbVerify = () => {
  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userRole, setUserRole] = useState(""); // Store the role
  const [userAssignment, setUserAssignment] = useState(""); // Store the assignment
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // You can change this value

  const token = localStorage.getItem("adminToken");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  // Fetch user data and current user's role/assignment
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const id = localStorage.getItem("id");
        const userResponse = await axios.get(`${BASE_URL}/api/v1/ltuser`,axiosConfig );

        const currentUserResponse = await axios.get(
          `${BASE_URL}/api/v1/currentUser/${id}`,axiosConfig
        );
        console.log(currentUserResponse, "currentUserResponse");
        const latestData = userResponse.data.reverse();
        setUserData(latestData);
        setFilteredData(latestData);

        const currentUser = currentUserResponse.data;
        const role = currentUserResponse.data.role;
        // Access the assignment array and get the first item as a string
        const assignment =
          currentUser.assignment && currentUser.assignment[0]
            ? currentUser.assignment[0]
            : "";

        console.log(assignment, "assignment");

        console.log(currentUserResponse.data, "data");
        setUserRole(role);
        setUserAssignment(assignment);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleStatusChange = (id, status) => {
    Swal.fire({
      title: `Are you sure you want to ${status}?`,
      text: "You won't be able to revert this action!",
      icon: status === "Rejected" ? "warning" : "question",
      showCancelButton: true,
      confirmButtonColor: status === "Rejected" ? "#d33" : "#3085d6",
      cancelButtonColor: "#aaa",
      confirmButtonText: `Yes, ${status}!`,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        let rejectionReason = ""; // For specific rejection labels
        if (status === "Rejected") {
          if (userRole === "admin" && userAssignment === "nhq_checker") {
            rejectionReason = "Rejected by NHQ";
          } else if (
            userRole === "admin" &&
            userAssignment === "cnc_approval"
          ) {
            rejectionReason = "Rejected by CNC";
          }
        }

        const payload = {
          status: status === "Rejected" ? rejectionReason : status,
          role: userRole,
          assignment: userAssignment,
        };

        axios
          .put(`${BASE_URL}/api/v1/ltuser/${id}`, { payload },axiosConfig)
          .then(() => {
            setUserData((prevData) =>
              prevData.map((user) =>
                user._id === id ? { ...user, status: payload.status } : user
              )
            );
            setFilteredData((prevData) =>
              prevData.map((user) =>
                user._id === id ? { ...user, status: payload.status } : user
              )
            );

            if (status === "Approved by CNC") {
              const honourableNumber = `HN-${Date.now()}`;
              console.log("Generated Honourable Number:", honourableNumber);
            }

            setMessage(`User status updated to: ${payload.status}`);
            Swal.fire(
              "Success",
              `User status updated to: ${payload.status}`,
              "success"
            );
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error updating status:", error);
            if (error.response?.data?.message === "User is not active") {
              Swal.fire("Error", "User is Not Active", "error");
              setLoading(false);
            } else {
              Swal.fire(
                "Error",
                "An error occurred while updating the status.",
                "error"
              );
              setLoading(false);
            }
          });
      }
    });
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = userData.filter((user) => {
      return (
        user.name?.toLowerCase().includes(query) ||
        user.email?.toLowerCase().includes(query) ||
        user.bsgUid?.toLowerCase().includes(query)
      );
    });
    console.log(filtered, "filtered");

    setFilteredData(filtered);
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  if (loading)
    return (
      <div className="flex justify-center items-center mt-56">
        <ThreeDots
          height="80"
          width="80"
          color="black"
          ariaLabel="loading-indicator"
        />
      </div>
    );

  // Get users for the current page
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = filteredData.slice(indexOfFirstUser, indexOfLastUser);

  // Pagination handlers
  const nextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-xl font-bold mb-4 text-center uppercase">
        LT Verification
      </h1>
      {message && <div className="text-green-500 mb-4">{message}</div>}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by name, email, or BSGUid"
          value={searchQuery}
          onChange={handleSearch}
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="grid gap-4">
        {currentUsers.length ? (
          currentUsers.map((user) => (
            <div
              key={user._id}
              className="p-4 border rounded shadow-md flex justify-between items-center"
            >
              <div>
                <p>
                  <strong>Name:</strong> {user.name}
                </p>
                <p>
                  <strong>Email:</strong> {user.email}
                </p>
                <p>
                  <strong>BSGUid:</strong> {user.bsgUid}
                </p>
                <p>
                  <strong>Status:</strong> {user.STATUS}
                </p>

                <p>
                  <strong>Status:</strong>
                  <span
                    className={`font-bold ${
                      user.status === "Approved by CNC"
                        ? "text-green-500"
                        : user.status === "Approved by NHQ"
                        ? "text-blue-500"
                        : user.status?.startsWith("Rejected by")
                        ? "text-red-500"
                        : "text-yellow-500"
                    }`}
                  >
                    {user.status || "Pending"}
                  </span>
                </p>
              </div>
              <div className="flex gap-2">
                <button
                  className="px-4 py-2 bg-[#1D56A5] text-white rounded"
                  onClick={() => openModal(user)}
                >
                  View
                </button>

                {userRole === "admin" && userAssignment === "nhq_checker" && (
                  <>
                    <button
                      className="px-4 py-2 bg-green-500 text-white rounded"
                      onClick={() =>
                        handleStatusChange(user._id, "Approved by NHQ")
                      }
                      disabled={
                        user.status?.startsWith("Approved by CNC") ||
                        user.status?.startsWith("Rejected by CNC") ||
                        user.status === "Approved by NHQ"
                      }
                    >
                      Approve by NHQ
                    </button>
                    <button
                      className="px-4 py-2 bg-red-500 text-white rounded"
                      onClick={() => handleStatusChange(user._id, "Rejected")}
                      disabled={
                        user.status?.startsWith("Approved by CNC") ||
                        user.status?.startsWith("Rejected by CNC") ||
                        user.status === "Rejected by NHQ"
                      }
                    >
                      Reject by NHQ
                    </button>
                  </>
                )}

                {userRole === "admin" &&
                  userAssignment === "cnc_approval" &&
                  user.status === "Approved by NHQ" && (
                    <>
                      <button
                        className="px-4 py-2 bg-green-500 text-white rounded"
                        onClick={() =>
                          handleStatusChange(user._id, "Approved by CNC")
                        }
                        disabled={user.status === "Approved by CNC"}
                      >
                        Approve by CNC
                      </button>
                      <button
                        className="px-4 py-2 bg-red-500 text-white rounded"
                        onClick={() => handleStatusChange(user._id, "Rejected")}
                        disabled={
                          user.status === "Rejected by CNC" ||
                          user.status === "Approved by CNC"
                        }
                      >
                        Reject by CNC
                      </button>
                    </>
                  )}
              </div>
            </div>
          ))
        ) : (
          <p className="flex justify-center text-center">No data to display</p>
        )}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between mt-4">
        <button
          onClick={prevPage}
          disabled={currentPage === 1}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Previous
        </button>
        <button
          onClick={nextPage}
          disabled={
            currentPage === Math.ceil(filteredData.length / itemsPerPage)
          }
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Next
        </button>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="User Details"
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-3xl p-6">
          <h2 className="text-center font-bold text-2xl mb-4 text-[#1D56A5] uppercase">
            User Details
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {selectedUser && (
              <>
                <p className="text-gray-700">
                  <strong className="font-semibold text-gray-900">Name:</strong>{" "}
                  {selectedUser.name}
                </p>
                <p className="text-gray-700">
                  <strong className="font-semibold text-gray-900">
                    Email:
                  </strong>{" "}
                  {selectedUser.email}
                </p>
                <p className="text-gray-700">
                  <strong className="font-semibold text-gray-900">
                    BSGUid:
                  </strong>{" "}
                  {selectedUser.bsgUid}
                </p>
                <p className="text-gray-700">
                  <strong className="font-semibold text-gray-900">
                    Date Of Birth:
                  </strong>{" "}
                  {selectedUser.dob}
                </p>
                <p className="text-gray-700">
                  <strong className="font-semibold text-gray-900">
                    State:
                  </strong>{" "}
                  {selectedUser.STATE}
                </p>
                <p className="text-gray-700">
                  <strong className="font-semibold text-gray-900">
                    Section:
                  </strong>{" "}
                  {selectedUser.SECTION}
                </p>

                <p className="text-gray-700">
                  <strong className="font-semibold text-gray-900">
                    OLD Honourable Number:
                  </strong>{" "}
                  {selectedUser.HONOURABLE_CHARGE_NO}
                </p>

                <p className="text-gray-700">
                  <strong className="font-semibold text-gray-900">
                    Status:
                  </strong>{" "}
                  {selectedUser.status || "Pending"}
                </p>

                <p className="text-gray-700">
                  <strong className="font-semibold text-gray-900">
                    {" "}
                    New Honourable Number:
                  </strong>{" "}
                  {selectedUser.honourableNumber || "N/A"}
                </p>
              </>
            )}
          </div>
          <div className="mt-6 flex justify-center">
            <button
              onClick={closeModal}
              className="px-6 py-2 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 transition duration-200"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HwbVerify;
