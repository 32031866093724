import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoImg from "../img/big logo (1).png";
import { FiLogOut } from "react-icons/fi";
import { FaHome } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { RiDashboardHorizontalFill } from "react-icons/ri";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import AddUser from "./Hwb/AddHwbLeader";
import AllBasicData from "./Course/AllBasicData";
import BasicCourse from "./Course/Basic";
import AdvanceCourse from "./Course/Advance";
import HwbList from "./Hwb/HwbList";
import UserList from "./userlist/TotalUser";
import AddHwbScout from "./Hwb/AddHwbBulkUpload";
import AllAdvanceCourse from "./Course/AllAdvanceData";
import ShowUserDashboard from './userlist/UserDashboard'
import AllHwbCourse from "./Course/AllHwbData";
import AddBSGState from "./Course/AddBSGState";
import AddBSGdate from "./Course/AddAltApplicationDate";
import AddRevenueDistrict from "./Course/RevenueDistrict";
import AddRevenueState from "./Course/RevenueState";
import Carousel from "./Course/AddCarousel";
import { AiFillFileAdd } from "react-icons/ai";
import { MdDateRange } from "react-icons/md";
import { BiSolidCarousel } from "react-icons/bi";
import { BsDatabaseFillAdd } from "react-icons/bs";
import { BsDatabaseAdd } from "react-icons/bs";
import { GrChapterAdd } from "react-icons/gr";
import ShowHwbDashboard from "./Hwb/ShowHwbDashboard";
import Allmarque from "../Component/Course/AllMarque";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import HwbOtherSection from "./Hwb/AddHwbOtherSection";
import ParchmnentNotIssued from "./Hwb/ParchmnentNotIssued";
import LtVerify from './Lt/LtVerify'
import ShowPreAltDashboard from "../Component/PreAlt/PreAltDashboard";
import AddPreAltExistingLeader from "../Component/PreAlt/AddPreAltExistingLeader";
import PreAltLeader from "../Component/Hwb/AddHwbLeader";
import PreAltBulkUpload from "../Component/PreAlt/PreAltBulkUpload";
import PreAltList from "../Component/PreAlt/PreAltList";
import ShowAltDashboard from "../Component/Alt/AltDashboard";
import AddAltExistingLeader from "../Component/Alt/AddAltExistingLeader";
import AltLeader from "../Component/Alt/AddAltLeader";
import AltBulkUpload from "../Component/Alt/AltBulkUpload";
import AltList from "../Component/Alt/AltList";
import LtBulkUpload from './Lt/LtBulkUpload'
import ShowltDashboard from './Lt/LtDashboard'
import LtList from "./Lt/LtList";
import CreateAdmin from "./CreateAdmin";
import AltVerify from "./Alt/AltVerify";


const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const navigate = useNavigate();

  const toggleHwbDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const togglePreAltDropdown = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
  };
  
  const toggleAltDropdown = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
  };

  const toggleltDropdown = () => {
    setIsDropdownOpen3(!isDropdownOpen3);
  };

  const handleLogout = async () => {
  
    toast.info("You will be logged out shortly.", {
 
  
      autoClose: 2000, // Duration for this message
      onClose: () => {
        // Perform logout actions after the toast is closed
        localStorage.removeItem("adminToken");
        localStorage.removeItem("encryptedToken");
        localStorage.removeItem("role");
        localStorage.removeItem("id");
        localStorage.removeItem("dashboardData");
        toast.success("Logged out successfully.", {
          position: "top-center",
        });
        setIsOpen(false);
        navigate("/");
      },
    });
  };
  

  return (
    <div className="flex md:min-h-screen">
      <ToastContainer />
      <div className="bg-gradient-to-b from-blue-900 to-blue-200 text-white md:w-64 p-4 shadow-lg">
        <div className="flex items-center justify-center py-8">
          <Link to="/dashboard">
            <img src={LogoImg} alt="Logo" className="w-56 h-10" />
          </Link>
        </div>

        <div className="space-y-4">
          <div>
            <h3 className="text-sm text-gray-200 uppercase tracking-wide">
              Admin Dashboard
            </h3>
            <div
              className="flex items-center space-x-3 p-3 mt-2 rounded-lg bg-blue-800 hover:bg-blue-900 cursor-pointer"
              onClick={() => navigate("/dashboard")}
            >
              <RiDashboardHorizontalFill className="text-white text-xl" />
              <span className="text-white text-sm font-semibold">
                Dashboard
              </span>
            </div>
          </div>

          {/* HWB Management Dropdown */}
          <div>
            <div
              className="flex items-center justify-between space-x-3 p-3 mt-2 rounded-lg bg-blue-800 hover:bg-blue-900 cursor-pointer"
              onClick={toggleHwbDropdown}
            >
              <AiFillFileAdd className="text-white text-xl" />
              <span className="text-white text-sm font-semibold">
                HWB MANAGEMENT
              </span>{" "}
              <MdOutlineArrowDropDownCircle />
            </div>
            {isDropdownOpen && (
              <div className="pl-6 space-y-2 mt-2">
                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/adduser")}
                >
                  <AiFillFileAdd className="text-white text-lg" />
                  <span className="text-white text-sm">Add HWB Leader</span>
                </div>
                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/addhwbscout")}
                >
                  <MdDateRange className="text-white text-lg" />
                  <span className="text-white text-sm">
                    Bulk Data Upload{" "}
                    <a style={{ color: "#FFFF00" }}>(Existing List)</a>
                  </span>
                </div>

                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/showhwblistdashboard")}
                >
                  <AiFillFileAdd className="text-white text-lg" />
                  <span className="text-white text-sm">HWB Dashboard Data</span>
                </div>
                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/hwblist")}
                >
                  <MdDateRange className="text-white text-lg" />
                  <span className="text-white text-sm">HWB List</span>
                </div>
                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/hwbothersection")}
                >
                  <AiFillFileAdd className="text-white text-lg" />
                  <span className="text-white text-sm">
                    Add HWB Leader (Existing Leader)
                  </span>
                </div>
                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/parchmentnotissued")}
                >
                  <AiFillFileAdd className="text-white text-lg" />
                  <span className="text-white text-sm">
                    Parchment Not Issued
                  </span>
                </div>
              </div>
            )}
          </div>

          <div>
            <div
              className="flex items-center justify-between space-x-3 p-3 mt-2 rounded-lg bg-blue-800 hover:bg-blue-900 cursor-pointer"
              onClick={togglePreAltDropdown}
            >
              <AiFillFileAdd className="text-white text-xl" />
              <span className="text-white text-xs font-semibold">
                PRE-ALT MANAGEMENT
              </span>{" "}
              <MdOutlineArrowDropDownCircle />
            </div>
            {isDropdownOpen1 && (
              <div className="pl-6 space-y-2 mt-2">
                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/addprealtuser")}
                >
                  <AiFillFileAdd className="text-white text-lg" />
                  <span className="text-white text-sm">Add Pre-Alt Leader</span>
                </div>
                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/addprealtbulk")}
                >
                  <MdDateRange className="text-white text-lg" />
                  <span className="text-white text-sm">
                    Bulk Data Upload{" "}
                    <a style={{ color: "#FFFF00" }}>(Existing List)</a>
                  </span>
                </div>

                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/showprealtdashboard")}
                >
                  <AiFillFileAdd className="text-white text-lg" />
                  <span className="text-white text-sm">
                    Pre-Alt Dashboard Data
                  </span>
                </div>
                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/prealtlist")}
                >
                  <MdDateRange className="text-white text-lg" />
                  <span className="text-white text-sm">Pre-Alt List</span>
                </div>
                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/prealtexistsection")}
                >
                  <AiFillFileAdd className="text-white text-lg" />
                  <span className="text-white text-sm">
                    Add Pre-Alt Leader (Existing Leader)
                  </span>
                </div>
              </div>
            )}
          </div>


          <div>
            <div
              className="flex items-center justify-between space-x-3 p-3 mt-2 rounded-lg bg-blue-800 hover:bg-blue-900 cursor-pointer"
              onClick={toggleAltDropdown}
            >
              <AiFillFileAdd className="text-white text-xl " />
              <span className="text-white text-xs font-semibold">
                ALT MANAGEMENT
              </span>{" "}
              <MdOutlineArrowDropDownCircle className=""/>
            </div>
            {isDropdownOpen2 && (
              <div className="pl-6 space-y-2 mt-2">
              
                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/addaltbulk")}
                >
                  <MdDateRange className="text-white text-lg" />
                  <span className="text-white text-sm">
                    Bulk Data Upload{" "}
                    <a style={{ color: "#FFFF00" }}>(Existing List)</a>
                  </span>
                </div>

                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/showaltlistdashboard")}
                >
                  <AiFillFileAdd className="text-white text-lg" />
                  <span className="text-white text-sm">
                    Alt Dashboard Data
                  </span>
                </div>
                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/altlist")}
                >
                  <MdDateRange className="text-white text-lg" />
                  <span className="text-white text-sm">Alt List</span>
                </div>

              </div>
            )}
          </div>

          
          <div>
            <div
              className="flex items-center justify-between space-x-3 p-3 mt-2 rounded-lg bg-blue-800 hover:bg-blue-900 cursor-pointer"
              onClick={toggleltDropdown}
            >
              <AiFillFileAdd className="text-white text-xl" />
              <span className="text-white text-xs font-semibold ">
                LT MANAGEMENT
              </span>{" "}
              <MdOutlineArrowDropDownCircle />
            </div>
            {isDropdownOpen3 && (
              <div className="pl-6 space-y-2 mt-2">
           
                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/addltbulk")}
                >
                  <MdDateRange className="text-white text-lg" />
                  <span className="text-white text-sm">
                    Bulk Data Upload{" "}
                    <a style={{ color: "#FFFF00" }}>(Existing List)</a>
                  </span>
                </div>

                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/showltlistdashboard")}
                >
                  <AiFillFileAdd className="text-white text-lg" />
                  <span className="text-white text-sm">
                    LT Dashboard 
                  </span>
                </div>
                <div
                  className="flex items-center space-x-3 p-2 rounded-lg bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  onClick={() => navigate("/ltlist")}
                >
                  <MdDateRange className="text-white text-lg" />
                  <span className="text-white text-sm">LT List</span>
                </div>

              </div>
            )}
          </div>

          <div>
            <div
              className="flex items-center space-x-3 p-3 mt-2 rounded-lg bg-blue-800 hover:bg-blue-900 cursor-pointer"
              onClick={() => navigate("/addbsgstate")}
            >
              <BsDatabaseFillAdd className="text-white text-xl" />
              <span className="text-white text-sm font-semibold">
                Add BSG State
              </span>
            </div>
            <div
              className="flex items-center space-x-3 p-3 mt-2 rounded-lg bg-blue-800 hover:bg-blue-900 cursor-pointer"
              onClick={() => navigate("/addbsgdate")}
            >
              <MdDateRange className="text-white text-xl" />
              <span className="text-white text-sm font-semibold">
                Add BSG Date
              </span>
            </div>

            <div
              className="flex items-center space-x-3 p-3 mt-2 rounded-lg bg-blue-800 hover:bg-blue-900 cursor-pointer"
              onClick={() => navigate("/revenuestate")}
            >
              <BsDatabaseAdd className="text-white text-xl" />
              <span className="text-white text-sm font-semibold">
                Add Revenue State
              </span>
            </div>
            <div
              className="flex items-center space-x-3 p-3 mt-2 rounded-lg bg-blue-800 hover:bg-blue-900 cursor-pointer"
              onClick={() => navigate("/revenuedistrict")}
            >
              <GrChapterAdd className="text-white text-xl" />
              <span className="text-white text-sm font-semibold">
                Add Revenue District
              </span>
            </div>
            <div
              className="flex items-center space-x-3 p-3 mt-2 rounded-lg bg-blue-800 hover:bg-blue-900 cursor-pointer"
              onClick={() => navigate("/carousel")}
            >
              <BiSolidCarousel className="text-white text-xl" />
              <span className="text-white text-sm font-semibold">
                Add Carousel
              </span>
            </div>
            {/* <div
              className="flex items-center space-x-3 p-3 mt-2 rounded-lg bg-blue-800 hover:bg-blue-900 cursor-pointer"
              onClick={() => navigate("/allmarque")}
            >
              <BiSolidCarousel className="text-white text-xl" />
              <span className="text-white text-sm font-semibold">
                Add Marque
              </span>
            </div> */}
          </div>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex-1">
        {/* Navbar */}
        <div className="bg-blue-900 h-20 flex items-center justify-between px-6 shadow-lg">
          {/* <h1 className="text-white text-3xl font-bold">ADMIN PANEL</h1> */}
              <div className="font-bold text-3xl text-white " style={{ fontFamily: "'Nunito Sans', sans-serif" }}>
        Admin <span className="text-white">Dashboard</span>
      </div>
          <div className="flex items-center space-x-1"  onClick={handleLogout}>
            <FiLogOut
              className="text-white text-2xl cursor-pointer hover:text-gray-300"
       
            />
            <span
              className="text-white text-md font-semibold cursor-pointer  hover:text-gray-300"
            
            >
              Logout
            </span>
          </div>
        </div>

        {/* Main Content */}
        <main className="p-6 bg-white shadow-inner">
          <Routes>
          <Route path="/userlist" element={<UserList />} />
            <Route path="/hwblist" element={<HwbList />} />
            <Route path="/alladvancedata" element={<AllAdvanceCourse />} />
            <Route path="/allhwbdata" element={<AllHwbCourse />} />
            <Route path="/addbasic" element={<BasicCourse />} />
            <Route path="/addadvance" element={<AdvanceCourse />} />
            <Route path="/allbasicdata" exact element={<AllBasicData />} />
            <Route path="/adduser" element={<AddUser />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/addbsgstate" element={<AddBSGState />} />
            <Route path="/addbsgdate" element={<AddBSGdate />} />
            <Route path="/revenuestate" element={<AddRevenueState />} />
            <Route path="/revenuedistrict" element={<AddRevenueDistrict />} />
            <Route path="/carousel" element={<Carousel />} />
            <Route path="/addhwbscout" element={<AddHwbScout />} />
            <Route path="/allmarque" element={<Allmarque />} />
            <Route path="/showhwblistdashboard" element={<ShowHwbDashboard />} />
            <Route path="/showuserlistdashboard" element={<ShowUserDashboard />} />
            <Route path="/hwbothersection" element={<HwbOtherSection />} />
            <Route path="/parchmentnotissued" element={<ParchmnentNotIssued />} />
            <Route path="/showprealtdashboard" element={<ShowPreAltDashboard />}/>
            <Route path="/addprealtuser" element={<PreAltLeader />} />
            <Route path="/addprealtbulk" element={<PreAltBulkUpload />} />
            <Route path="/prealtexistsection" element={<AddPreAltExistingLeader />}/>
            <Route path="/prealtlist" element={<PreAltList />} />
            <Route path="/altexistsection" element={<AddAltExistingLeader />} />
            <Route path="/addaltuser" element={<AltLeader />} />
            <Route path="/addaltbulk" element={<AltBulkUpload />} />
            <Route path="/showaltlistdashboard" element={<ShowAltDashboard />} />
            <Route path="/altlist" element={<AltList />} />  
            <Route path="/addltbulk" element={<LtBulkUpload />} />
            <Route path="/showltlistdashboard" element={<ShowltDashboard />} />
            <Route path="/ltlist" element={<LtList />} /> 
            <Route path="/create-admin" element={<CreateAdmin />} /> 
            <Route path="/alt-verify" element={<AltVerify  />} /> 
            <Route path="/lt-verify" element={<LtVerify  />} /> 
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default Sidebar;
