import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { BASE_URL } from "../constant/constant";
import CountUp from "react-countup";

const Dashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [altCount, setAltCount] = useState(0);
  const [ltCount, setLtCount] = useState(0);
  const [loaderPercentage, setLoaderPercentage] = useState(0);
  const [loaderColor, setLoaderColor] = useState("bg-gray-200");
  const [role, setUserRole] = useState("");
  const [assignment, setUserAssignment] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const navigate = useNavigate();

  const token = localStorage.getItem("adminToken");

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("dashboardData"));

    if (storedData) {
      setUserCount(storedData.userCount);
      setAltCount(storedData.altCount);
      setLtCount(storedData.ltCount);
      setInitialLoading(false);
    } else {
      const totalAPIs = 3;
      let completedAPIs = 0;

      const updateLoader = () => {
        completedAPIs++;
        setLoaderPercentage((completedAPIs / totalAPIs) * 100);
        if (completedAPIs === 1) setLoaderColor("bg-yellow-500");
        if (completedAPIs === 2) setLoaderColor("bg-yellow-500");
        if (completedAPIs === 3) setLoaderColor("bg-yellow-500");
      };

      const tempData = { userCount: 0, altCount: 0, ltCount: 0 };

      Promise.all([
        axios
          .get(`${BASE_URL}/api/v1/hwbuser`, axiosConfig)
          .then((response) => {
            tempData.userCount = response.data.length;
            setUserCount(response.data.length);
            updateLoader();
          })
          .catch(() => {
            toast.error("Error fetching user data.");
          }),
        axios
          .get(`${BASE_URL}/api/v1/altuser`, axiosConfig)
          .then((response) => {
            tempData.altCount = response.data.length;
            setAltCount(response.data.length);
            updateLoader();
          })
          .catch(() => {
            toast.error("Error fetching ALT user data.");
          }),
        axios
          .get(`${BASE_URL}/api/v1/ltuser`, axiosConfig)
          .then((response) => {
            tempData.ltCount = response.data.length;
            setLtCount(response.data.length);
            updateLoader();
          })
          .catch(() => {
            toast.error("Error fetching LT user data.");
          }),
      ])
        .then(() => {
          localStorage.setItem("dashboardData", JSON.stringify(tempData));
        })
        .finally(() => {
          setInitialLoading(false);
        });
    }

    axios
      .get(
        `${BASE_URL}/api/v1/currentUser/${localStorage.getItem("id")}`,
        axiosConfig
      )
      .then((response) => {
        const currentUser = response.data;
        setUserRole(currentUser.role);
        setUserAssignment(
          currentUser.assignment && currentUser.assignment[0]
            ? currentUser.assignment[0]
            : ""
        );
      })
      .catch(() => {
        toast.error("Error fetching current user data.");
      });
    // }, []);

    axios
      .get(
        `${BASE_URL}/api/v1/currentUser/${localStorage.getItem("id")}`,
        axiosConfig
      )
      .then((response) => {
        const currentUser = response.data;
        setUserRole(currentUser.role);
        setUserAssignment(
          currentUser.assignment && currentUser.assignment[0]
            ? currentUser.assignment[0]
            : ""
        );
      })
      .catch(() => {
        toast.error("Error fetching current user data.");
      });
  }, []);

  const cardData = [
    // {
    //   label: "Total User",
    //   count: userCount,
    //   color: "bg-teal-800",
    //   route: "/userlist",
    // },
    {
      label: "HWB Holder",
      count: userCount,
      color: "bg-teal-500",
      route: "/hwblist",
    },
    {
      label: "Pre-Alt Holder",
      count: 0,
      color: "bg-yellow-500",
      route: "/prealtlist",
    },
    {
      label: "Alt Holder",
      count: altCount,
      color: "bg-purple-500",
      route: "/altlist",
    },
    {
      label: "LT Holder",
      count: ltCount,
      color: "bg-red-500",
      route: "/ltlist",
    },
  ];

  const data = {
    labels: cardData.map((item) => item.label),
    datasets: [
      {
        label: "Count",
        data: cardData.map((item) => item.count),
        backgroundColor: ["#4A90E2", "#E94E77", "#F8B133", "#AF67E9"],
        borderRadius: 6,
        barThickness: 30,
      },
    ],
  };

  return (
    <div className="min-h-screen p-4">
      <ToastContainer />
      {initialLoading ? (
        <div className="flex items-center justify-center h-screen bg-gray-100 ">
          <div className="relative w-48 h-48 -mt-32">
            {/* Circle background */}
            <div className="absolute inset-0 rounded-full border-8 border-gray-300"></div>

            {/* Loader circle */}
            <div
              className={`absolute inset-0 rounded-full border-8 ${loaderColor}`}
              style={{
                clipPath: "inset(0 50% 0 0)",
                transform: `rotate(${(loaderPercentage / 100) * 360}deg)`,
                transformOrigin: "50% 50%",
              }}
            ></div>

            {/* Center text */}
            <div className="absolute inset-0 flex items-center justify-center text-center">
              <span className="text-lg font-semibold text-gray-800 animate-pulse">
                Loading... {Math.round(loaderPercentage)}%
              </span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {cardData.map((item, index) => (
              <div
                key={index}
                className={`bg-gradient-to-r ${item.color} text-white rounded-lg shadow-lg p-4 h-40`}
              >
                <div className="text-center font-bold text-lg mb-2 uppercase">
                  {item.label}
                </div>
                <div className="text-center text-5xl font-extrabold mb-2">
                  <CountUp
                    start={0}
                    end={item.count}
                    duration={3}
                    separator=","
                  />
                </div>
                <div className="flex justify-around mt-4">
                  <button
                    className="bg-white text-blue-500 font-semibold py-1 px-3 rounded-full shadow-md hover:bg-blue-100"
                    onClick={() => navigate(item.route)}
                  >
                    List
                  </button>
                  <button
                    className="bg-white text-blue-500 font-semibold py-1 px-3 rounded-full shadow-md hover:bg-blue-100"
                    onClick={() =>
                      navigate(`/show${item.route.slice(1)}dashboard`)
                    }
                  >
                    Dashboard
                  </button>
                </div>
              </div>
            ))}
            {role === "superadmin" && (
              <div className="flex-shrink-0 bg-gradient-to-r text-white rounded-lg shadow-lg p-4 w-full h-40 bg-lime-400">
                <div className="text-center font-bold text-lg mb-2 uppercase">
                  Create Admin
                </div>
                <div
                  className="text-center font-semibold"
                  onClick={() => {
                    navigate("/create-admin");
                  }}
                >
                  <div className="space-y-2">
                    <span
                      className="text-white cursor-pointer rounded-md px-3 text-md uppercase bg-gradient-to-r from-red-500 to-red-700 shadow-md hover:shadow-lg hover:from-red-600 hover:to-red-800 transition-all"
                      style={{ fontFamily: "Roboto Mono, monospace" }}
                    >
                      NTC Uploader
                    </span>
                    <br />
                    <span
                      className="text-white cursor-pointer rounded-md px-3 text-md uppercase bg-gradient-to-r from-red-500 to-red-700 shadow-md hover:shadow-lg hover:from-red-600 hover:to-red-800 transition-all"
                      style={{ fontFamily: "Roboto Mono, monospace" }}
                    >
                      NHQ Checker
                    </span>
                    <br />
                    <span
                      className="text-white cursor-pointer rounded-md px-3 text-md uppercase bg-gradient-to-r from-red-500 to-red-700 shadow-md hover:shadow-lg hover:from-red-600 hover:to-red-800 transition-all"
                      style={{ fontFamily: "Roboto Mono, monospace" }}
                    >
                      CNC-Approver
                    </span>
                    <br />
                    <span
                      className="text-white cursor-pointer rounded-md px-3 text-md uppercase bg-gradient-to-r from-red-500 to-red-700 shadow-md hover:shadow-lg hover:from-red-600 hover:to-red-800 transition-all"
                      style={{ fontFamily: "Roboto Mono, monospace" }}
                    >
                      Super Admin
                    </span>
                  </div>
                </div>
              </div>
            )}

            {(role === "superadmin" ||
              assignment === "nhq_checker" ||
              assignment === "cnc_approval") && (
              <div className="flex-shrink-0 bg-gradient-to-r text-white rounded-lg shadow-lg p-4 w-full h-40 bg-red-800">
                <div className="text-center font-bold text-xl mb-2 uppercase">
                  ALT VERIFIED APPLICATION
                </div>
                <div
                  className="text-center font-semibold mt-8"
                  onClick={() => {
                    navigate("/alt-verify");
                  }}
                >
                  <span
                    className="text-red-500 bg-white cursor-pointer  rounded-2xl py-1 px-2 text-lg"
                    style={{ fontFamily: "Roboto Mono, monospace" }}
                  >
                    {" "}
                    ✔ Verified
                  </span>
                </div>
              </div>
            )}

            {(role === "superadmin" ||
              assignment === "nhq_checker" ||
              assignment === "cnc_approval") && (
              <div className="flex-shrink-0 bg-gradient-to-r text-white rounded-lg shadow-lg p-4 w-full h-40 bg-green-800">
                <div className="text-center font-bold text-xl mb-2 uppercase">
                  LT VERIFIED APPLICATION
                </div>
                <div
                  className="text-center font-semibold mt-8"
                  onClick={() => {
                    navigate("/lt-verify");
                  }}
                >
                  <span
                    className="text-red-500 bg-white cursor-pointer  rounded-2xl py-1 px-2 text-lg"
                    style={{ fontFamily: "Roboto Mono, monospace" }}
                  >
                    {" "}
                    ✔ Verified
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="bg-white rounded-lg shadow-md p-4 mt-6 h-96">
            <Bar data={data} />
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
